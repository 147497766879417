<template>
  <!-- Begin Page Content -->
  <div class="container-fluid" style="min-height: 600px">
    <div class="card shadow mb-4">
      <div
        class="card-header d-sm-flex align-items-center justify-content-between mb-4 py-3"
      >
        <h4 class="m-0 font-weight-bold text-primary">Roles</h4>
        <router-link
          class="startbuttons d-none d-sm-inline-block float-right btn btn-sm btn-success shadow-sm btn-sm"
          :to="'/roles/add'"
        >
          Add Role
        </router-link>
      </div>
      <div class="card-body">
        <div class="table-responsive">
          <!-- <h5 class="m-0 font-weight-bold text-primary">Your Roles</h5> -->
          <br />
          <vue-good-table
            :pagination-options="{
              enabled: false,
            }"
            :rows="role_row"
            :columns="role_column"
          >
            <template slot="table-row" scope="props">
              <span v-if="props.column.label == 'Action'" class="d-flex p-0 m-0 justify-content-center aling-items-center">
                <tr class="no-border-td-tr">
                  <td
                    class="no-border-td-tr"
                  >
                    <router-link to="#">
                      <i class="btn btn-primary">Update</i>
                    </router-link>
                  </td>
                  <td
                    class="no-border-td-tr"
                  >
                      <i class="btn btn-danger" @click="deleteRole(props.row.id)">Delete</i>
                  </td>
                </tr>
              </span>
            </template>
          </vue-good-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import user from "@/ajax/user";
import { mapState, mapActions } from "vuex";
import Vue from "vue";
import moment from "moment";
moment.locale('en');
import axios from "axios";
import myMixin from "@/mixins/Permission";

export default {
  name: "Tasks",
  mixins: [myMixin],
  data: function () {
    return {
      role_column:[
        {
          label:'Name',
          field:'name'
        },
        {
          label:"Description",
          field:'description'
        },
        {
          label:"Action",
          field:'action'
        }
      ],
      role_row:[],
      permissions: window.localStorage.getItem("permissions"),
    };
  },
  mounted() {
    this.getRolesDropdown();
  },
  methods: {
    ...mapActions("userStore", ["setCurrentUser", "logoutUser"]),
    ...mapActions("common", ["setCurrentPage"]),
    getRolesDropdown(){
      user.getUserRoleDropdown().then(request=>{
        this.role_row=request.data.data;
      });
    },
    deleteRole(id){
      user.deleteRole(id).then(response=>{
        alert("Role deleted succesfully.....")
        window.location.reload();
        });
    }
  },
  computed: {
    ...mapState("userStore", ["currentUser"]),
  },
};
</script>

<style scoped>
.startbuttons {
  padding: 5px 30px 5px 30px;
  margin: 10px;
  font-size: 20px;
}
.disableButton {
  opacity: 0.5;
  pointer-events: none;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
